.navbar {
  position: fixed;
  width: 100%;
  z-index: 100;
}

.navbar_container {
  position: relative;
  display: flex;
  background-color: var(--mainPurple);
  border: none;
  transition: all 0.5s ease;
  align-items: center;
}

.navbar_container_scrollTop {
  justify-content: center;
}

.navbar_container_scrollRest {
  justify-content: flex-start;
}

.navbar_logo {
  min-width: 65px;
  transition: max-width 0.5s ease;
  margin: 10px 0 0 10px;
  visibility: visible;
}

.navbar_title {
  width: 100%;
  display: flex;
  justify-content: center;
}

.navbar_menu_container {
  display: flex;
  align-items: center;
}

.navbar_contact {
  display: none;
}

.navbar_desktop_menu {
  display: none;
}

.navbar_button {
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  border: none;
  font-size: 1.2em;
  font-weight: bold;
  margin: 0 5px;
  transition: all 0.5s ease;
  font-family: RobotoRegular;
  white-space: nowrap;
}

.navbar_button:hover {
  color: var(--mainBlue);
}

@media screen and (min-width: 1024px) {
  .navbar_container {
    background-color: white;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    justify-content: space-around;
  }

  .navbar_title {
    display: none;
  }

  .navbar_menu_container {
    margin-right: 10px;
  }

  .navbar_contact {
    display: block;
  }

  .navbar_desktop_menu {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  .navbar_button {
    padding: 0 5px 0 5px;
  }
}
