.footer {
  display: none;
}

@media screen and (min-width: 768px) {
  .footer {
    background-color: var(--mainBlue);
    position: relative;
    color: white;
    display: flex;
    justify-content: space-around;
    padding: 40px 0 40px 0;
  }

  .footer_link {
    color: white;
    font-size: 1.2em;
    display: flex;
    align-items: center;
    text-align: center;
  }

  .footer_socials {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
  }
}
