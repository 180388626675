.burger_menu {
  position: absolute;
  right: 0;
}

.burger_menu_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  width: 45px;
  height: 45px;
  background-color: transparent;
  border: none;
  border-radius: 0.3rem;
  background-color: var(--mainPurple);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.burger_menu_btn:hover,
.burger_menu_btn:active {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

@media screen and (min-width: 1024px) {
  .burger_menu {
    display: none;
  }
}
