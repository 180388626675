:root {
  --bg: #f9f9f9;
  --mainBlue: rgba(84, 171, 181, 1);
  --mainBlue10: rgba(84, 171, 181, 0.1);
  --mainBlue30: rgba(84, 171, 181, 0.3);
  --mainBlue50: rgba(84, 171, 181, 0.5);
  --mainPurple: rgba(96, 91, 131, 1);
  --mainOrange: rgb(255 102 51);
  --mainOrange50: rgba(255, 102, 51, 0.8);
  --mainGrey: rgba(50, 50, 50, 1);
  --lightGrey: rgb(100, 100, 100);
}

@font-face {
  font-family: RobotoThin;
  src: url("./assets/fonts/Roboto-Thin.ttf");
  font-display: swap;
}

@font-face {
  font-family: RobotoLight;
  src: url("./assets/fonts/Roboto-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: RobotoRegular;
  src: url("./assets/fonts/Roboto-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: RobotoMedium;
  src: url("./assets/fonts/Roboto-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: RobotoBold;
  src: url("./assets/fonts/Roboto-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: Alata;
  src: url("./assets/fonts/alata-regular.ttf");
  font-display: swap;
}

body {
  font-family: RobotoLight;
  font-size: 1rem;
  color: var(--mainGrey);
  background-color: var(--bg);
}

ul {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  list-style-type: none;
}

.global_cover {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.global_container {
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
}

.global_content {
  grid-column-start: 2;
  grid-column-end: 2;
  text-align: justify;
  justify-self: center;
  width: 100%;
}

.global_margin_top {
  margin-top: 300px;
}

.global_custom_link {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85);
  border: 1px solid #d9d9d9;
  background: #fff;
}

.global_custom_btn {
  /* display: flex;
  align-items: center; */
  font-family: RobotoRegular;
  text-transform: uppercase;
  color: white;
  border-radius: 10px;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.global_orange_btn {
  background-color: var(--mainOrange);
}

.global_orange_btn:hover,
.global_orange_btn:focus {
  background-color: var(--mainOrange);
  color: var(--mainPurple);
  font-weight: bold;
}

.global_blue_btn {
  background-color: var(--mainBlue);
}

.global_blue_btn:hover,
.global_blue_btn:focus {
  background-color: var(--mainBlue);
  color: var(--mainPurple);
  font-weight: bold;
}

.global_audit_intro {
  display: flex;
  flex-direction: column;
}

.global_section_title {
  color: var(--mainPurple);
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
}

.global_section_subtitle {
  font-weight: bold;
}

.global_hidden_title {
  visibility: hidden;
  position: absolute;
}

.global_link {
  color: var(--mainBlue);
  font-weight: bold;
}

.global_link:hover {
  color: var(--mainOrange);
  text-decoration: underline;
}

.global_circle {
  display: none;
  position: absolute;
  z-index: -1;
}

.benefit {
  padding-top: 20px;
}

.pay_benefits {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pay_benefits_item {
  width: 100%;
}

.pay_benefits_item > h2 {
  text-align: center;
  color: var(--mainPurple);
}

@media screen and (min-width: 540px) {
  .global_audit_intro {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .global_section_title {
    font-size: 2em;
  }

  .words {
    grid-column-start: 1;
    padding: 0 75px 0 0;
    width: 100%;
  }

  .benefit {
    grid-column-start: 2;
    padding: 0 0 0 75px;
    width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .global_margin_top {
    margin-top: 400px;
  }

  .global_circle {
    display: block;
  }

  .pay_benefits {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-top: 75px;
  }

  .pay_benefits_item {
    max-width: 225px;
  }
}

@media screen and (min-width: 1440px) {
  .global_container {
    grid-template-columns: 1fr 4fr 1fr;
  }

  .pay_benefits_item {
    max-width: 300px;
  }

  .pay_benefits_item > h2 {
    white-space: nowrap;
  }
}
